<template>
    <v-container class="footer">
        <v-row class="justify-left align-center">
            <v-col cols="" class="text-white">
                <div>© Qoro Quantum Ltd, 2024 All rights reserved.</div>                                
            </v-col>
            <v-col cols="2" lg="2" sm="2">
                <v-btn variant="text" color="white" @click="$router.push('/privacy-policy')">Privacy Policy</v-btn>
            </v-col> 
            <!-- <v-col cols="2" lg="2" sm="2">
                <v-btn variant="text" color="white" @click="$router.push('/privacy-policy')">Terms of Service</v-btn>
            </v-col>  -->
            <v-col cols="2" lg="2" sm="2">
                <v-btn variant="text" color="white" @click="$router.push('/imprint')">Imprint</v-btn>
            </v-col>            
            <v-spacer></v-spacer>     
            <v-col lg="1" sm="2">
                <v-icon style="color: white" size="x-large"
                    @click="openLink('https://www.linkedin.com/company/qoro-quantum/')" target="_blank">
                    mdi-linkedin
                </v-icon>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'FooterBar',
    methods: {
        openLink(link) {
            window.open(link, '_blank');
        }
    }
    // Add component properties and methods here
}
</script>

<style scoped>
.footer {
    max-width: 100%;
    background-color: black;   
    position: relative; 
}
</style>