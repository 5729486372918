<template>
    <v-container class="about">
        <h2 class="header-text pb-3"> {{ title }} </h2>
        <div v-if="!img || isMobile()">
            <p>
                {{ body }}
            </p>
        </div>
        <div v-else>
            <v-row class="justify-left align-center">
                <v-col cols="8"> {{ body }}</v-col>
                <v-col cols="4">
                    <v-img class="background-image" width="275px" :src="img" cover></v-img>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'ContentRowComponent',
    props: {
        title: {
            type: String,
            required: true
        },
        body: {
            type: String,
            required: true
        },
        img: {
            type: String,
            required: false
        }
    },
    methods: {
        isMobile() {
            return (screen.width <= 760);
        }
    },
}
</script>

<style scoped>
.about {
    max-width: 100%;
}

.header-text {
    color: black;
}
</style>