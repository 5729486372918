<template>
  <HeaderBar />
  <div class="home-page">
    <v-container style="max-width: 100%; height: 100%;">
      <v-row class="justify-center">
        <img :style="imageSize" :src="require('@/assets/logo.png')" />
      </v-row>

      <v-row style="margin-top:420px;" class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <ContentRowComponent :title="qoro.title" :body="qoro.body" />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row v-if="isMobile()" class="grey-row justify-center">
        <v-col lg="7" sm="10">
          <ContentRowComponent :title="about.title" :body="about.body" :img="about.img" />
        </v-col>
      </v-row>
      <v-row v-else class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <ContentRowComponent :title="about.title" :body="about.body" :img="about.img" />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <ContentRowComponent :title="dqc.title" :body="dqc.body" />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row v-if="updates.length != 0" class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <LatestUpdates :updates="updates" />
        </v-col>
      </v-row>

      <v-divider v-if="updates.length != 0"></v-divider>

      <v-row class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <ContentRowComponent :title="contact.title" :body="contact.body" />
        </v-col>
      </v-row>
    </v-container>
  </div>

  <FooterBar />

</template>

<script>

import HeaderBar from './HeaderBar.vue'
import FooterBar from './FooterBar.vue'
import LatestUpdates from './LatestUpdates.vue'
import ContentRowComponent from './ContentRowComponent.vue'

export default {
  name: 'HomePage',
  data() {
    return {
      updates: [
        {
          id: 0,
          title: 'Qoro Quantum in ESA BIC',
          description: 'Qoro Quantum accepted in the ESA BIC Bavaria.',
          img: require("@/assets/ESA.png")
        },
        {
          id: 1,
          title: 'Qoro Quantum in the CDL Bootcamp',
          description: 'Qoro Quantum is excited to be part of the Creative Destruction Lab Quantum Bootcamp.',
          img: "https://creativedestructionlab.com/wp-content/uploads/2018/07/pink-black.jpg"
        },
        {
          id: 2,
          title: 'Qoro Quantum Joins the QBN',
          description: 'Qoro Quantum is excited to join the Quantum Business Network.',
          img: require("@/assets/qbn.png")
        },
        {
          id: 3,
          title: 'Qoro Quantum at Berlin Quantum Pinoneer',
          description: 'The Berlin Quantum Pioneer program is an intensive one week program for quantum startups.',
          img: require("@/assets/berlin_pioneer.png")
        },
        {
          id: 4,
          title: 'Qoro Quantum, GBIP in Germany',
          description: 'Qoro Quantum tours Germany with GBIP.',
          img: require("@/assets/ukri.png")
        },
        {
          id: 5,
          title: 'Qoro Quantum in Nvidia Inception',
          description: 'Qoro Quantum is accepted to the Nvidia Inception program.',
          img: require("@/assets/nvidia.png")
        }
      ],
      windowWidth: window.innerWidth,
      qoro: {
        title: "Qoro Quantum",
        body: "Qoro Quantum is a cutting-edge deep tech company focused on advancing the network stack for scalable, distributed quantum computing. We provide an automated quantum algorithm processing pipeline and a cloud-based network controller that synchronizes multi-vendor computing clusters for optimal quantum application execution. Our platform enables seamless integration between quantum and classical systems, ensuring efficient resource allocation across a variety of computing clusters, including FPGA, GPU, CPU, and QPU. With a vision to build secure, country-wide quantum networks, Qoro Quantum is driving innovation in quantum datacenter networks and hybrid applications, positioning itself at the forefront of the quantum computing revolution. Our scalable infrastructure is designed to unlock the full potential of quantum technology for industries, facilitating the transition towards practical, real-world quantum applications."
      },
      about: {
        title: "Multi-Node Quantum Computing Today",
        body: "At Qoro Quantum, we build the network stack that brings the promise of multi-node quantum computing into reality. Using advanced classical networking technologies, we enable the seamless integration of multiple quantum processors into a unified system, creating a scalable and efficient ecosystem for distributed quantum computing. Our innovations in networking infrastructure allow quantum algorithms to be processed and distributed across multiple quantum nodes, ensuring that different quantum processors—regardless of vendor or architecture—can work together in harmony. Today, our technology empowers next-generation quantum computing by addressing the critical needs of scalability, security, and efficiency. By distributing quantum workloads across interconnected nodes, we unlock the potential for solving problems that single-node quantum systems cannot handle alone. Our platform ensures secure and synchronized communication between quantum and classical systems, providing optimal performance for hybrid computing environments. Qoro Quantum’s infrastructure is built to support today’s quantum advancements while laying the foundation for the future. We are enabling the practical, real-world deployment of multi-node quantum computing, driving the quantum revolution forward with scalable, secure, and efficient solutions for distributed quantum datacenters. Our goal is to be at the forefront of the global quantum computing network, turning theoretical quantum capabilities into powerful applications today.",
        img: require('@/assets/dqc.png')
      },
      dqc: {
        title: "Quantumly Distributed Quantum Computing",
        body: "Distributed quantum computing will enable scalable quantum computing, but will require complex quantum networking technologies. To achieve distributed quantum computing, quantum interconnect technologies will be needed, this includes quantum switches, quantum routers, transducers, and other novel techologies. There will be a need to reliably distribute entanglement across the network which involves scheduling and precise network control. Leading up to this, the classical networking infrastructure and standards will be needed. Qoro Quantum takes the first step in developing the network stack leading up to quantumly-connected distributed quantum computing"
      },
      contact: {
        title: "Contact Us",
        body: "For inquiries, partnerships, or general information, please contact us at: team@qoroquantum.de"
      }
    }
  },
  components: {
    HeaderBar,
    FooterBar,
    LatestUpdates,
    ContentRowComponent
  },
  methods: {
    isMobile() {
      return (screen.width <= 760);
    }
  },
  computed: {
    imageSize() {
      if (this.isMobile()) {
        return 'width:70%';
      } else if (this.windowWidth < 1000) {
        return 'width:70%';
      } else {
        return 'width:60%';
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-page {
  width: 100%;
  background-color: #000;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 250px;
}

.grey-row {
  background-color: #F2F2F2;
}
</style>