<template>
    <v-card>
        <v-card-title> {{ update.title }}</v-card-title>
    
        <v-img            
            height="150px"            
            :src="update.img"                
        ></v-img>

        <v-card-text>
            {{ update.description }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'UpdateComponent',
    props: {
        update: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.news-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.news-preview__image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 10px;
}

.news-preview__description {
    text-align: center;
    font-size: 14px;
    color: #333;
}
</style>